import React, { useState } from 'react';
import Modal from 'react-modal';
import './modalCustomStyle.css';

const MVModal = ({
    isOpen,
    afterOpenCallback,
    onCloseCallback,
    name,
    styles,
    children
}) => {
    const defaultStyles = {
        overlay: {
            zIndex: 5,
            backgroundColor: 'rgba(39, 39, 39, 0.8)'
        },
        content: {
            padding: 0,
            border: 0,
            background: 'rgb(255, 255, 255)',
            overflow: 'auto',
            borderRadius: '4px',
            outline: 'none',
            position: 'absolute'
        }
    };

    const mVModalStyles = {
        ...defaultStyles,
        ...styles
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onAfterOpen={afterOpenCallback}
                onRequestClose={onCloseCallback}
                contentLabel="Example Modal"
                className={name}
                style={mVModalStyles}
            >
                {children}
            </Modal>
        </div>
    );
};

export default MVModal;
